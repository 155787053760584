<template>
  <div v-if="visibleModule">
    <a-modal :title="title" :footer="null" v-model="show" :maskClosable="false" :closable="false">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="params"
        :rules="rules"
      >
        <a-form-model-item label="选择项目：" prop="projectName">
          <a-select
            v-model="params.projectName"
            class="inputClass"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option v-for="option in projectName" :key="option.id" :value="option.id">{{
              option.value
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="模块名称：" prop="moduleName">
          <a-select
            v-model="params.moduleName"
            class="inputClass"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            @change="handleChange"
          >
            <a-select-option v-for="option in moduleName" :key="option.id" :value="option.value">{{
              option.value
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="魔方选择：" prop="rubikcube" v-if="this.rubikcubeVisble">
          <a-input placeholder="请输入魔方名称或编号" v-model="params.rubikcube" class="inputClass"/>
        </a-form-model-item>

        <a-form-model-item label="排序序号：" prop="sort">
          <a-input placeholder="请输入序号" v-model="params.sort" class="inputClass"/>
        </a-form-model-item>

        <a-form-model-item label="状态：" prop="state" style="padding-left: 6%;">
          <a-select
            v-model="params.state"
            class="inputClass"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option v-for="option in state" :key="option.id" :value="option.id">{{
              option.value
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

      </a-form-model>
      <div style="margin-left: 60%;">
        <a-button type="primary" class="footerButton" @click="submit">
          提交
        </a-button>
        <a-button @click="cancel">返回</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>

import debounce from "lodash/debounce";

export default {
  name: "AddModuleBindingManage",
  props: {
    visibleModule: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      // 弹框显示
      show: true,
      // 魔方选择下拉框是否显示
      rubikcubeVisble: false,
      title: '',
      id: '',
      // 状态
      state: [
        {
          id: '1',
          value: '禁用'
        },
        {
          id: '2',
          value: '启用'
        }
      ],
      // 模块名称
      moduleName: [
        {
          id: '1',
          value: '今日份惊喜'
        },
        {
          id: '2',
          value: '优选好店'
        },
        {
          id: '3',
          value: '园区公告'
        },
        {
          id: '4',
          value: '胶囊广告'
        },
        {
          id: '5',
          value: '魔方组件'
        }
      ],
      // 项目名称
      projectName: [
        {
          id:'1',
          value:'项目A'
        },
        {
          id:'2',
          value:'项目B'
        },
        {
          id:'3',
          value:'项目C'
        },
        {
          id:'4',
          value:'项目D'
        }
      ],
      params: {
        // 项目名称
        projectName: undefined,
        // 模块名称
        moduleName: undefined,
        // 魔方选择
        rubikcube: "",
        // 排序
        sort: "",
        // 状态
        state: undefined
      },
      rules:{
        // 项目名称
        projectName: [{ required: true, message: '请填写完整信息', trigger: 'change' }],
        // 模块名称
        moduleName: [{ required: true, message: '请填写完整信息', trigger: 'change' }],
        // 魔方选择
        rubikcube: [{ required: true,  message: '请填写完整信息', trigger: 'blur' },],
        // 排序
        sort:[
          { required: true,  message: '请填写完整信息', trigger: 'blur' },
          { required: false, pattern: new RegExp(/^(0|\+?[1-9][0-9]*)$/, "g"), message: '只能输入数字' },
        ],
        // 状态
        state: [{ required: true, message: '请填写完整信息', trigger: 'change' }],
          
      },
    }
  },
  methods: {
    // 模块名称下拉框选择
    handleChange(value,data) {
      if(value == '魔方组件'){
        this.rubikcubeVisble = true
      } else {
        this.rubikcubeVisble = false
      }
    },

    // 关闭蒙层的操作(取消校验)
    async cancel(){
      if(this.params.projectName !== undefined || this.params.moduleName !== undefined || this.params.sort !== '' || this.params.state !== undefined){
        this.$emit("close", false);
      } else {
        this.$emit("close", true);
      }
    },

    //添加
    submit:debounce (function() {
      
      if(this.params.floorState === '0'){
        var state = false
      } else {
        var state = true
      }      
      let data = {};
      data.floorName =  this.params.floorName;
      data.floorSort =  this.params.floorSort;
      data.floorState =  state;
      data.userLevel = this.params.userLevel;

      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if(this.title === '新建楼层') {
            const res = await floorAdd(data)
            if(res.code === '200'){
              this.$message.success('新建成功');
              this.getFloor();
              this.$refs.ruleForm.resetFields();
              this.visibleFloor = false;
            } else {
              this.$message.warning(res.msg)
            }
          } else {
            data.id = this.id;
            const res = await floorUpdate(data)
            if(res.code === '200'){
              this.$message.success('编辑成功');
              this.getFloor();
              this.visibleFloor = false;
              this.$refs.ruleForm.resetFields();
            } else {
              this.$message.warning(res.msg)
            }
          }
          
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, 500),
  }
};
</script>

<style lang="scss" scoped>
</style>